.App {
  text-align: center;
  color: #474747;
  font-family: 'Raleway', sans-serif;
  background-color: #fff;

  width: 100%;
  --mainColor: #0066FF;
}

h1, h2, h3, p{
  margin: 0;
}

a{
  color: white;
}
a:hover{
  color: var(--mainColor);
}

h1{
  margin-top:2rem;
  margin-bottom:2rem;
  font-size: 5rem;
  font-family: 'Work Sans', sans-serif;
  line-height: 4rem;
}

h2{
  font-size: 3rem;
}

mark{
  background-color: white;
  color: var(--mainColor);
  padding: 0rem 0.3rem;
}

.black{
  background-color: var(--mainColor);
  color: white;
}

.right{
  padding: 2rem;
}
.right img{
  box-shadow: 0 0 20px #999;
  -webkit-transform: rotate(6deg);
          transform: rotate(6deg);
}


.mainHead{
  display: flex;
  flex-direction: column;
}
.mainHead > div{
  flex:1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mainHead img{
  width: 100%;
}


.err{
  background: #e8175d2d;
  border: 3px solid var(--mainColor);
  padding: 1rem;
}


.payoff{
  padding: 2rem 1rem;
  font-size: 1.25rem;
}
.showcase{
  padding: 0 1rem;
}
.showcase img{
  width: 100%;
}

.cta a{
  color: #756c6c;
}

.card{
  min-height: 250px;
  display: flex;
  align-items: center;
  padding: 1rem;
}

.card .inner{
  border: 1px dashed #756c6c;
  width: 100%;
  height: 100%;
  min-height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card img{
  width: 100%;
}

.alt{
  font-family: 'Allison';
  font-weight: normal;
  color: var(--mainColor);
  display: block;
}


input, button{
  font-family: 'Raleway', sans-serif;
}


.form{
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form input{
  font-size: 1.5rem;
  padding: .5rem;
  width: 100%;
  border: 1px solid #ddd;
  text-align: center;
  color: #474747;
}
.form button{
  margin-top:1rem;
}

.form p{
  font-size: .7rem;
  color: #474747;
  padding: .5rem;
  
}
.form span{
  text-decoration: underline;
  cursor: pointer;
}
.form a{
  color: #474747;
}
.form a:hover{
  color: var(--mainColor);
}

.cta{
  padding: 1rem;
}
.cta p{
  padding: 0.5rem;
}


button{
  font-size: 1.5rem;
  padding: .5rem 1.5rem;
  border: 2px solid var(--mainColor);
  border-radius: 20px;
  color: var(--mainColor);
  background: white;
  text-transform: uppercase;
  transition: -webkit-transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
button:hover{
  background-color: var(--mainColor);
  color: white;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

button:disabled{
  opacity: 0.35;
  -webkit-transform: scale(0.85);
          transform: scale(0.85);
  pointer-events: none;
}



footer{
  margin-top: 1rem;
  padding: 2rem 1rem;
  background: #363636;
  color: #a8a7a7;
}
footer p{
  margin: 1rem 0;
}
footer a{
  color: white;
}
footer a:hover{
  background-color: white;
  color: var(--mainColor);
  text-decoration: none;
}
footer .small{
  font-size: 0.6rem;
}


.wrapper{
  width: 100%;
  margin: auto;
}


.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--mainColor);
  -webkit-animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
          animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  -webkit-animation-delay: -0.24s;
          animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  -webkit-animation-delay: -0.12s;
          animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  -webkit-animation-delay: 0;
          animation-delay: 0;
}
@-webkit-keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


@media screen and (min-width: 560px) {
  html{
    font-size: 110%;
  }
  .wrapper{
    width: 90%;
  }
}

@media screen and (min-width: 720px) {
  html{
    font-size: 120%;
  }
  .wrapper{
    width: 80%;
  }
  
}

@media screen and (min-width: 960px) {
  html{
    font-size: 130%;
  }
  .wrapper{
    width: 70%;
  }
  .mainHead{
    flex-direction: row;
  }
  .mainHead .left{
    margin-bottom: 2rem;
  }
}

@media screen and (min-width: 1120px) {
  html{
    font-size: 140%;
  }
  .wrapper{
    width: 60%;
  }
}

@media screen and (min-width: 1360px) {
  html{
    font-size: 150%;
  }
  .wrapper{
    width: 50%;
  }
}